
.items {
    background-image: url(photos/ItemsIsItems.jpg);
    background-size: cover;
}

.island {
    background-image: url(photos/FloatingIslandSide.png);
    background-size: cover;
}

.insurance {
    background-image: url(photos/InsuranceValidator.png);
    background-size: cover;
    background-blend-mode: multiply;
}

.guild {
    background-image: url(photos/RedemptionsGuildCover.png);
    background-size: cover;
}

.toyota {
    background-image: url(photos/SEMA_Toyota_Connected.jpg);
    background-size: cover;
    background-blend-mode: multiply;
}

line {
    content: "\f005";
    display: inline-block;
    width: 25%;
    margin: 25px auto 30px;
    border-top: solid 5px;
}

.TextWrapRight {
    float: right;
    margin: 10px;
    max-width: 35%;
    height: auto;
}

.TextWrapLeft {
    float: left;
    margin: 0 20px 20px 0;
    max-width: 35%;
    height: auto;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /*width: 100%;*/
  }

.wrap {
    text-align: justify;
    text-indent: 2em;
    word-wrap: keep-all;
}

.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
