@import url(https://use.fontawesome.com/f43d9f75f4.js);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* HERE STARTS THE MAGIC */
header {
  text-align: center;
  color: #fff;
  background: #2e2e2e;

  text-shadow: 1px 1px #2e2e2e;
  width: 100%;
  z-index: 1;
  height: 100%;
  overflow: hidden;
  left: 0;
}

@media only screen and (min-height: 650px){
  header {
    position: fixed;
  }
  .content-wrapper {
    position: absolute;
  }
}

.content-wrapper {
  background-color: #f8f8f8;
  top: 100%;
  min-height: 0%;
  z-index: 2;
  width: 100%;
  scroll-behavior: smooth;
}
/* HERE ENDS THE MAGIC */
header .container {
  padding-top: 115px;
  padding-bottom: 50px;
}

header img {
  display: block;
  margin: 0 auto 20px;
  border-radius: 50%;
}

header .intro-text .name {
  display: block;
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

header .intro-text .tagline {
  font-size: 1.25em;
  font-weight: 300;
}

header .intro-text .skills {
  font-size: 1.25em;
  font-family: "Helvetica";
  font-weight: 300;
  -webkit-text-stroke: 0.15px #2e2e2e;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
}

section {
  padding: 100px 0;
  width: 100%;
}

section h2 {
  margin: 0;
  font-size: 3em;
}

hr.line-light,
hr.line-primary {
  margin: 25px auto 30px;
  padding: 0;
  width: 75%;
  border: 0;
  border-top: solid 5px;
  text-align: center;
}

section.primary h2 {
  color: #2e2e2e;
}

section.success {
  background-color: #2e2e2e;
  color: #fff;
}

.image-links {
  max-width: 100%;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.icon-links {
  height: 15vmin;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.items {
    background-image: url(/static/media/ItemsIsItems.66982f32.jpg);
    background-size: cover;
}

.island {
    background-image: url(/static/media/FloatingIslandSide.3f89ff27.png);
    background-size: cover;
}

.insurance {
    background-image: url(/static/media/InsuranceValidator.aceb5c8a.png);
    background-size: cover;
    background-blend-mode: multiply;
}

.guild {
    background-image: url(/static/media/RedemptionsGuildCover.afdaa0fa.png);
    background-size: cover;
}

.toyota {
    background-image: url(/static/media/SEMA_Toyota_Connected.366545ea.jpg);
    background-size: cover;
    background-blend-mode: multiply;
}

line {
    content: "\f005";
    display: inline-block;
    width: 25%;
    margin: 25px auto 30px;
    border-top: solid 5px;
}

.TextWrapRight {
    float: right;
    margin: 10px;
    max-width: 35%;
    height: auto;
}

.TextWrapLeft {
    float: left;
    margin: 0 20px 20px 0;
    max-width: 35%;
    height: auto;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    /*width: 100%;*/
  }

.wrap {
    text-align: justify;
    text-indent: 2em;
    word-wrap: keep-all;
}

.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

