@import url("https://use.fontawesome.com/f43d9f75f4.js");

/* HERE STARTS THE MAGIC */
header {
  text-align: center;
  color: #fff;
  background: #2e2e2e;

  text-shadow: 1px 1px #2e2e2e;
  width: 100%;
  z-index: 1;
  height: 100%;
  overflow: hidden;
  left: 0;
}

@media only screen and (min-height: 650px){
  header {
    position: fixed;
  }
  .content-wrapper {
    position: absolute;
  }
}

.content-wrapper {
  background-color: #f8f8f8;
  top: 100%;
  min-height: 0%;
  z-index: 2;
  width: 100%;
  scroll-behavior: smooth;
}
/* HERE ENDS THE MAGIC */
header .container {
  padding-top: 115px;
  padding-bottom: 50px;
}

header img {
  display: block;
  margin: 0 auto 20px;
  border-radius: 50%;
}

header .intro-text .name {
  display: block;
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

header .intro-text .tagline {
  font-size: 1.25em;
  font-weight: 300;
}

header .intro-text .skills {
  font-size: 1.25em;
  font-family: "Helvetica";
  font-weight: 300;
  -webkit-text-stroke: 0.15px #2e2e2e;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
}

section {
  padding: 100px 0;
  width: 100%;
}

section h2 {
  margin: 0;
  font-size: 3em;
}

hr.line-light,
hr.line-primary {
  margin: 25px auto 30px;
  padding: 0;
  width: 75%;
  border: 0;
  border-top: solid 5px;
  text-align: center;
}

section.primary h2 {
  color: #2e2e2e;
}

section.success {
  background-color: #2e2e2e;
  color: #fff;
}

.image-links {
  max-width: 100%;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.icon-links {
  height: 15vmin;
  margin-left: auto;
  margin-right: auto;
  display: block;
}